// components/ClientLogos.js
import AldeliaLogo from '../../assets/SVG/ClientsLogo/AldeliaLogo.svg';
import GizLogo from '../../assets/SVG/ClientsLogo/GizLogo.svg';
import McgillLogo from '../../assets/SVG/ClientsLogo/McgillLogo.svg';
import NEHZALogo from '../../assets/SVG/ClientsLogo/NHEZALogo.svg';
import PrecisionLogo from '../../assets/SVG/ClientsLogo/PrecisionLogo.svg';

const clientLogos = [
  { src: AldeliaLogo, alt: 'Aldelia Logo' },
  { src: GizLogo, alt: 'Giz Logo' },
  { src: McgillLogo, alt: 'Mcgill Logo' },
  { src: NEHZALogo, alt: 'NEHZA Logo' },
  { src: PrecisionLogo, alt: 'Precision Logo' },
];

export default clientLogos;
