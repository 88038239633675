import BantuEd from '../../assets/Images/BantuEdProductImage.webp';
import Egolu from '../../assets/Images/egolu-app.webp';

const ProductsData = {
  en: {
    heroTitle: 'Experience Our Cutting-Edge Software Solutions',
    heroSubtitle:
      'Welcome to the forefront of innovation. We are proud to offer a suite of products that embody advanced technologies and groundbreaking solutions.',
    products: [
      {
        title: 'BantuEd',
        description:
          'BantuEd leads the way in revolutionizing education through advanced technologies and tailored solutions.',
        learnMoreUrl: 'https://bantued.com',
        image: BantuEd,
        buttontext: 'Learn more',
      },
      {
        title: 'EGOLU App',
        description:
          'EGOLU App is a groundbreaking solution revolutionizing payroll and human resources management.',
        learnMoreUrl: 'https://egolu.co',
        image: Egolu,
        buttontext: 'Learn more',
      },
    ],
    cta: {
      title: 'OUR PRODUCT',
      content: 'Explore our innovative product!',
      callToAction: "Let's Talk!",
    },
  },
  fr: {
    heroTitle: 'Découvrez Nos Solutions Logicielles de Pointe',
    heroSubtitle:
      "Bienvenue à la pointe de l'innovation. Nous sommes fiers de vous proposer une gamme de produits qui incarnent des technologies avancées et des solutions révolutionnaires.",
    products: [
      {
        title: 'BantuEd',
        description:
          "BantuEd ouvre la voie à la révolution de l'éducation grâce à des technologies avancées et des solutions sur mesure.",
        learnMoreUrl: 'https://bantued.com',
        image: BantuEd,
        buttontext: 'En savoir plus',
      },
      {
        title: 'Application EGOLU',
        description:
          "L'application EGOLU est une solution révolutionnaire pour la gestion des paies et des ressources humaines.",
        learnMoreUrl: 'https://egolu.co',
        image: Egolu,
        buttontext: 'En savoir plus',
      },
    ],
    cta: {
      title: 'NOTRE PRODUIT',
      content: 'Découvrez notre produit innovant !',
      callToAction: 'Discutons-en !',
    },
  },
};

export default ProductsData;
