import ATRCyberLogo from '../../assets/SVG/PartnersLogo/ATRCyberLogo.svg';
import AyaLogo from '../../assets/SVG/PartnersLogo/AyaLogo.svg';
import BantuLogo from '../../assets/SVG/PartnersLogo/BantuLogo.svg';
import BipocLogo from '../../assets/SVG/PartnersLogo/BipocLogo.svg';
import BogolLogo from '../../assets/SVG/PartnersLogo/BogolLogo.svg';
import DigitalMainStLogo from '../../assets/SVG/PartnersLogo/DigitalMainStLogo.svg';
import EgoluLogo from '../../assets/SVG/PartnersLogo/EgoluLogo.svg';
import EPlogo from '../../assets/SVG/PartnersLogo/EPlogo.svg';
import EuclidesLogo from '../../assets/SVG/PartnersLogo/EuclidesLogo.svg';
import ISMLogo from '../../assets/SVG/PartnersLogo/ISMLogo.svg';
import JKMediDesignLogo from '../../assets/SVG/PartnersLogo/JKMediDesignLogo.svg';
import KPDILogo from '../../assets/SVG/PartnersLogo/KPDILogo.svg';
import NeuralAILogo from '../../assets/SVG/PartnersLogo/NeuralAILogo.svg';
import RiipenLogo from '../../assets/SVG/PartnersLogo/RiipenLogo.svg';
import RockPowerLogo from '../../assets/SVG/PartnersLogo/RockPowerLogo.svg';
import RocksteadyConsultingLogo from '../../assets/SVG/PartnersLogo/RocksteadyConsultingLogo.svg';
import StartupYMMLogo from '../../assets/SVG/PartnersLogo/StartupYMMLogo.svg';
import VelocityLogo from '../../assets/SVG/PartnersLogo/VelocityLogo.svg';
import YoshiLogo from '../../assets/SVG/PartnersLogo/YoshiLogo.svg';

const partnersLogos = [
  { src: ATRCyberLogo, alt: 'ATRCyber Logo' },
  { src: AyaLogo, alt: 'Aya Logo' },
  { src: BantuLogo, alt: 'Bantu Logo' },
  { src: BipocLogo, alt: 'Bipoc Logo' },
  { src: DigitalMainStLogo, alt: 'Digital Main St Logo' },
  { src: EuclidesLogo, alt: 'Euclides Logo' },
  { src: ISMLogo, alt: 'ISM Logo' },
  { src: JKMediDesignLogo, alt: 'JK Medi Design Logo' },
  { src: KPDILogo, alt: 'KPDILogo Logo' },
  { src: NeuralAILogo, alt: 'Neural AI Logo' },
  { src: RiipenLogo, alt: 'Riipen Logo' },
  { src: RockPowerLogo, alt: 'Rock Power Logo' },
  { src: RocksteadyConsultingLogo, alt: 'Rocksteady Consulting Logo' },
  { src: StartupYMMLogo, alt: 'Startup YMM Logo' },
  { src: VelocityLogo, alt: 'Velocity Logo' },
  { src: YoshiLogo, alt: 'Yoshi Logo' },
];

export default partnersLogos;
